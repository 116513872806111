.layout-container {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .layout-header {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
    padding: 8px 0;
  }
  .layout-footer {
    margin-top: auto;
    background-color: #424242;
    max-width: 420px;
    padding: 16px 0;
  }
}
