@import "./component/layout.scss";
@import "./component/typography.scss";
@import "./antd-custom.scss";

$colors: (
  "primary-main": $primary-main,
  "neutral-10": $neutral-10,
  "neutral-20": $neutral-20,
  "neutral-30": $neutral-30,
  "neutral-40": $neutral-40,
  "neutral-50": $neutral-50,
  "neutral-60": $neutral-60,
  "neutral-70": $neutral-70,
  "neutral-80": $neutral-80,
  "neutral-90": $neutral-90,
  "neutral-100": $neutral-100,
  "primary-purple": $primary-purple,
);

body {
  max-width: 420px;
  font-family: $font-family !important;
  margin: 0 auto !important;
  overflow-x: hidden;
  background-color: #e5e5e5 !important;
}

.w-100 {
  width: 100%;
}

.ant-typography {
  font-family: $font-family !important;
  letter-spacing: -0.006em;
}

@each $name, $key in $colors {
  .text-color-#{$name} {
    color: $key !important;
  }
}

$max: 64;
$offset: 1;
$unit: "px"; // Feel free to change the unit.
@mixin list-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i * 8 + $unit};
    }
    $i: $i + $offset;
  }
}

// Margins
@include list-loop(".mt-", "margin-top");
@include list-loop(".mr-", "margin-right");
@include list-loop(".mb-", "margin-bottom");
@include list-loop(".ml-", "margin-left");
@include list-loop(".mx-", "margin");
// Paddings
@include list-loop(".pt-", "padding-top");
@include list-loop(".pr-", "padding-right");
@include list-loop(".pb-", "padding-bottom");
@include list-loop(".pl-", "padding-left");
@include list-loop(".px-", "padding");

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.use-pointer {
  cursor: pointer;
}

.ant-collapse {
  background-color: #ffffff;
  .ant-collapse-header {
    align-items: center !important;
  }
  .ant-collapse-content {
    background-color: #f5f5f5;
    ol {
      margin: 0 0;
    }
  }
}

.ant-collapse-item-active {
  .ant-collapse-header-text {
    font-weight: bold;
  }
}

ol.parens-around {
  counter-reset: list;
}

ol.parens-around > li {
  list-style: none;
  counter-increment: list;
}

ol.parens-around {
  list-style-type: lower-alpha;
  &.lower-roman > li::marker {
    content: "(" counter(list, lower-roman) ")\a0" !important;
  }
  & > li::marker {
    content: "(" counter(list, lower-alpha) ")\a0" !important;
  }
}
