$font-weights: (
  "regular": 400,
  "medium": 600,
  "bold": 700,
);

@each $name, $key in $font-weights {
  .text-xs-#{$name} {
    font-weight: $key !important;
    font-size: 10px !important;
    line-height: 8px !important;
  }
}

@each $name, $key in $font-weights {
  .text-s-#{$name} {
    font-weight: $key !important;
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

@each $name, $key in $font-weights {
  .text-m-#{$name} {
    font-weight: $key !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

@each $name, $key in $font-weights {
  .text-l-#{$name} {
    font-weight: $key !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

@each $name, $key in $font-weights {
  .heading-s-#{$name} {
    font-weight: $key !important;
    font-size: 20px !important;
    line-height: 28px !important;
  }
}

@each $name, $key in $font-weights {
  .heading-m-#{$name} {
    font-weight: $key !important;
    font-size: 28px !important;
    line-height: 36px !important;
  }
}

@each $name, $key in $font-weights {
  .heading-l-#{$name} {
    font-weight: $key !important;
    font-size: 36px !important;
    line-height: 44px !important;
  }
}
