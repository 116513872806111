$bg-layout: #21232d;
$bg-outer: #e5e5e5;
$header-color: #21232d;
$bg-sidebar: #272727;

// Color Palette Revamp
$neutral-10: #ffffff;
$neutral-20: #f5f5f5;
$neutral-30: #ededed;
$neutral-40: #e0e0e0;
$neutral-50: #c2c2c2;
$neutral-60: #9e9e9e;
$neutral-70: #757575;
$neutral-80: #616161;
$neutral-90: #424242;
$neutral-100: #0a0a0a;

$primary-main: #5099fc;
$primary-surface: #dcebfe;
$primary-border: #c5ddfe;
$primary-hover: #4380d2;
$primary-pressed: #284d7e;
$primary-focus: #d8e6f9;
$primary-purple: #0d43fb;

$warning-main: #e2ae29;
$warning-surface: #fff8d6;
$warning-border: #fff4bb;
$warning-hover: #d4b92b;
$warning-pressed: #806f1a;

$danger-main: #f6274c;
$danger-surface: #fdd4db;
$danger-border: #fcb7c3;
$danger-hover: #cd213f;
$danger-pressed: #7b1426;
$danger-light: #feecef;

$success-main: #1aaa55;
$success-surface: #d1eedd;
$success-border: #b3e3c6;
$success-hover: #168e47;
$success-pressed: #0d552b;

$info-main: #0b5cd7;
$info-surface: #cedef7;
$info-border: #aec9f2;
$info-hover: #094db3;
$info-pressed: #052e6b;
$info-green: #1ec80f;

$secondary-sky-blue: #25aae1;
$secondary-light-blue: #dff4fd;
$secondary-blue: #386bb6;
$secondary-dark-blue: #12273c;
$secondary-gradient-blue: linear-gradient(
  126.75deg,
  #0b3886 10.83%,
  #386bb6 100%
);
$transparent-sky-blue: rgba(116, 190, 232, 0.1);
$secondary-dark-blue-tosca: #255582;
$secondary-pink: #c781b7;
$secondary-purple: #5d29e2;
$secondary-dark-grey: #272727;
$secondary-dark-gradient: linear-gradient(
  151.38deg,
  #101220 40.28%,
  #142e46 98.9%
);
$secondary-blue-dark-gradient: linear-gradient(
  91.77deg,
  #255582 -24.6%,
  rgba(80, 153, 252, 0) 115.02%
);
$secondary-jade-green: linear-gradient(
  92.39deg,
  #1aaa55 -67.39%,
  #255582 102.05%
);
$secondary-dark-mustard: linear-gradient(
  90.66deg,
  #fcc812 0.57%,
  #aa8607 102.11%
);
$secondary-lavender-indigo: linear-gradient(
  90.66deg,
  #8c60ff 0.57%,
  #5d29e2 102.11%
);
$secondary-purple-pink: linear-gradient(to right, #e448ce, #5f00e3);
$secondary-super-dark-blue: #12141a;
$secondary-deep-dark-blue: #1b1d2a;
$deep-koamaru: #35395f;
$philippine-gray: #929292;
$eerie-black: #111727;
$eerie-black-light: #1e1e1e;
$charleston-green: #272727;
$space-cadet: #4e4a2b;
$taupe-gray: #878787;
$dark-charcoal: #2f2f2f;
$philippine-silver: #b8b8b8;
$urobilin: #e2ae29;
$taupe-gray: #888888;
$raisin-black: #232323;
$water: #c9ecfc;
$purple: #5d29e2;
$secondary-deep-dark-blue: #1b1d2a;
$secondary-deep-dark-grey: #202020;
$secondary-deep-dark-blue: #1b1d2a;
$purple: #5d29e2;
$cloud-burst: #272b4c;
$dark: #1d203a;
$pickled-bluewood: #333855;
$snow-drift: #f9f9f9;
$deep-blue: #272b4c;
$dark-blue: #1d203a;
$blueberry: #5099fc;
$light-carmine-pink: #e8747a;
$transparent: transparent;
$candy-pink: #e8747a;
$sunglow: #ffc633;
$mirage: #212121;
$cloud: #c4c4c4;
$gun-powder: #3b405b;
$mid-blue: #3566a8;
$blue-zodiac: #132c50;
$cloud-burst: #2b2f4e;
$dark-jungle-green: #212121;
$battleship-grey: #848484;
$tufts-blue: #407aca;
$violet-blue: #4a18cb;
$ebony-clay: #252845;

/* sizes */
$padding-size: 8px;
$header-size: 64px;

@font-face {
  font-family: "Signika";
  src: url("../../public/fonts/signika-bold.woff2") format("woff2"),
    url("../../public/fonts/signika-bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Signika";
  src: url("../../public/fonts/signika-regular.woff2") format("woff2"),
    url("../../public/fonts/signika-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Signika";
  src: url("../../public/fonts/signika-semibold.woff2") format("woff2"),
    url("../../public/fonts/signika-semibold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

$font-family: "Signika", "Quicksand", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
